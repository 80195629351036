import { createApp } from 'vue';
import { createBootstrap } from 'bootstrap-vue-next';
import { createPinia } from 'pinia';
import laravelVapor from 'laravel-vapor';
import { v4 as uuidv4 } from 'uuid';
import '@/helpers/language-picker.js';
import '@/worker/update';
import {
  init as SentryInit,
  browserTracingIntegration,
  captureConsoleIntegration,
  dedupeIntegration,
  replayIntegration,
} from '@sentry/vue';
import router from './router';
import App from './App.vue';

import '@/assets/scss/main.scss';

window.Vapor = laravelVapor;

const app = createApp(App);

app.mixin({
  methods: {
    generateUniqueRef() {
      const text = uuidv4();
      const date = new Date();

      return `${text}_${date.getTime()}`;
    },
  },
});

SentryInit({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    browserTracingIntegration({ router }),
    replayIntegration(),
    captureConsoleIntegration({ levels: ['error', 'warn'] }),
    dedupeIntegration(),
  ],
  environment: process.env.NODE_ENV,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost:4000',
    /^https:\/\/dev\.myautocare\.curacel\.co/,
    /^https:\/\/sandbox\.myautocare\.curacel\.co/,
    /^https:\/\/myautocare\.curacel\.co/,
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app
  .use(
    createBootstrap({
      components: true,
      directives: true,
    })
  )
  .use(createPinia())
  .use(router);
app.mount('#app');
