export default [
  {
    path: 'policies/upload-requirements',
    name: 'whatsapp.policies.upload-requirements',
    component: () => import('@/views/whatsapp/PurchaseForm.vue'),
  },
  {
    path: 'claims/upload-requirements',
    name: 'whatsapp.claims.upload-requirements',
    component: () => import('@/views/whatsapp/ClaimForm.vue'),
  },
];
