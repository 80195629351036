import { defineStore } from 'pinia';
import axios from 'axios';

interface Insurer {
  id: number;
  name: string;
  code: string;
  email: string;
  currency: string;
  allow_repair_estimates: boolean
  third_party_policy_enabled?: boolean;
}

const Axios = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  headers: { 'Content-Type': 'application/json' },
});

export const useInsurerStore = defineStore('insurer', {
  state: (): { insurer: Insurer | null } => ({ insurer: null }),
  getters: {
    getInsurer: (state) => state.insurer,
  },
  actions: {
    async setInsurer(code: string) {
      const response = await Axios.get(`/insurer/${code}`);
      this.insurer = response.data.data;
      return response;
    },
  },
});
